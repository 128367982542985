import React, { FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Routes } from '../../constants/Routes';
import Image from 'next/image';
import NavBarControls from './NavBarControls';
import { NavBarLinks } from './NavBarLinks';
import { Search } from './Search';
import { NavigationMenuContext } from '../../contexts/NavigationMenuContext';
export const WIDTH_FOR_MENU_ICON_TO_APPEAR = 1400;
type Props = {
    className: string;
};

//INFO
// The NavBar cannot be added to the top level component (_app). Main reason is that _app does not work with getServerSiderProps
// the only way to make a request to get the navigation menu is through a HTTP request. The only chance to make a request
// in _app is using getInitialProps, which have a chance to work on client side which will lead to a problem, where the request will be stopped
// by NextJS by displaying an error of 'Module "net" is missing'

//TODO research react server components
export const NavBar: FunctionComponent<Props> = ({ className }) => {
    const categories = useContext(NavigationMenuContext);
    const ref = useRef<HTMLElement>(null);
    const [displaySearch, setDisplaySearch] = useState(false);
    const [displayCategoryLinks, setDisplayCategoryLinks] = useState(false);

    useEffect(() => {
        //Adding scroll detection to close the NavBarLinks for mobile devices
        const handleScroll = () => {
            if (window.innerWidth <= WIDTH_FOR_MENU_ICON_TO_APPEAR && displayCategoryLinks) {
                setDisplayCategoryLinks(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (window.innerWidth > WIDTH_FOR_MENU_ICON_TO_APPEAR) {
            setDisplayCategoryLinks(true);
        }
    }, []);

    return (
        <nav className={`navbarContainer navbar navbar-expand-lg bg-primary ${className} w-100`} ref={ref}>
            <div className='container d-flex flex-column align-items-center justify-content-center position-relative ps-0 pe-0 ps-md-2 pe-md-2 ps-lg-2 pe-lg-2'>
                <div
                    className={`navbarBrandContainer d-flex flex-row align-items-center justify-content-between w-100 pt-md-3 pb-md-1 pb-2 pt-2 pt-lg-4 pe-3 ps-3 pe-md-0 ps-md-0 pe-lg-0 ps-lg-0`}>
                    <a
                        className={`navBrand navbar-brand position-relative d-inline-block`}
                        href={Routes.openHomepage()}>
                        <Image src={'/woman_logo.svg'} alt={'woman-bg-logo'} className='normalize-nextjs-image' fill />
                    </a>
                    <div className='d-flex flex-row navbar-controls'>
                        <div className='d-flex flex-row align-items-center'>
                            <NavBarControls
                                className='d-flex'
                                collapseContainerId={'navbar-search'}
                                imageSrc={'/search.svg'}
                                onClick={() => setDisplaySearch(!displaySearch)}
                            />
                        </div>
                        <NavBarControls
                            collapseContainerId={'navbar-supported-content'}
                            className={`navbarMenu`}
                            onClick={() => {
                                setDisplayCategoryLinks(!displayCategoryLinks);
                            }}
                            imageSrc={displayCategoryLinks ? '/close.svg' : '/menu.svg'}
                        />
                    </div>
                </div>
                {displaySearch ? (
                    <Search
                        id={'navbar-search'}
                        className='d-flex flex-column justify-content-end'
                        onClose={() => {
                            setDisplaySearch(false);
                        }}
                    />
                ) : null}
                <NavBarLinks
                    categories={categories}
                    display={displayCategoryLinks}
                    onClick={() => setDisplayCategoryLinks(false)}
                    id={'navbar-supported-content'}
                    className={`navbar-links pt-lg-1 ps-3`}
                />
            </div>
        </nav>
    );
};
