import React, { FunctionComponent, useState } from 'react';
import { useRouter } from 'next/router';
import { Routes } from '../../constants/Routes';
import Image from 'next/image';
import styles from '../../styles/Search.module.scss';

type Props = {
    id: string;
    className?: string;
    onClose: () => void;
};

export const Search: FunctionComponent<Props> = ({ id, className, onClose }) => {
    const [text, setText] = useState('');
    const router = useRouter();
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            router.push(Routes.openSearch(text));
            if (onClose) {
                onClose();
            }
        }
    };

    return (
        <div className={`navbarSearchContainer ${className}`}>
            <div id={''} className='d-flex flex-row'>
                <div
                    className={`d-flex input-group p-1 ${styles.searchContainer} navbarSearchChildContainer`}
                    role='search'>
                    <input
                        className={`form-control ${styles.searchInput}`}
                        placeholder='Search'
                        aria-label='Search'
                        onChange={(evt) => setText(evt.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <div
                        role='button'
                        className={`input-group-append d-flex flex-row align-items-center justify-content-center ${styles.searchActionIcon}`}
                        onClick={() => {
                            router.push(Routes.openSearch(text));
                            if (onClose) {
                                onClose();
                            }
                        }}>
                        <Image
                            alt={'search-icon'}
                            src={'/search_icon.svg'}
                            width={20}
                            height={20}
                            className='normalize-nextjs-image'
                            id='basic-addon2'></Image>
                    </div>
                </div>
                <div
                    role='button'
                    className={`input-group-append d-flex flex-row align-items-center justify-content-center ${styles.closeSearchInput}`}
                    data-bs-toggle='collapse'
                    data-bs-target={`#${id}`}
                    onClick={onClose}>
                    <Image
                        alt={'search-icon'}
                        src={'/close_icon.svg'}
                        width={14}
                        height={14}
                        className='normalize-nextjs-image'
                        id='basic-addon2'></Image>
                </div>
            </div>
        </div>
    );
};
