import { AdModel } from './models/AdModel';

export const AD_MANAGER_ID = '/26641721/woman.bg/';

const mappingSpecialProject1 = [
    {
        index: [1200, 0],
        sizes: [
            [300, 600],
            [300, 250],
        ],
    },
    {
        index: [768, 0],
        sizes: [
            [300, 600],
            [300, 250],
        ],
    },
    {
        index: [480, 0],
        sizes: [[300, 250], [300, 600], 'fluid'],
    },
    {
        index: [320, 0],
        sizes: [[300, 250], [300, 600], 'fluid'],
    },
];

const mappingSpecialProject2 = [
    {
        index: [1200, 0],
        sizes: [
            [728, 90],
            [650, 150],
            [890, 200],
        ],
    },
    {
        index: [768, 0],
        sizes: [
            [728, 90],
            [650, 150],
        ],
    },
    {
        index: [480, 0],
        sizes: [[300, 250], [300, 600], [300, 300], 'fluid'],
    },
    {
        index: [320, 0],
        sizes: [[300, 250], [300, 600], [300, 300], 'fluid'],
    },
];

const mappingSpecialProjectHeader = [
    {
        index: [1200, 0],
        sizes: [[1800, 560]],
    },
    {
        index: [768, 0],
        sizes: [[1800, 560]],
    },
    {
        index: [480, 0],
        sizes: [
            [360, 250],
            [320, 250],
        ],
    },
    {
        index: [320, 0],
        sizes: [
            [360, 250],
            [320, 250],
        ],
    },
];

const mapping1 = [
    {
        index: [1200, 0],
        sizes: [
            [300, 600],
            [300, 250],
        ],
    },
    {
        index: [768, 0],
        sizes: [
            [300, 600],
            [300, 250],
        ],
    },
    {
        index: [480, 0],
        sizes: [[300, 250], [300, 600], 'fluid'],
    },
    {
        index: [320, 0],
        sizes: [[300, 250], [300, 600], 'fluid'],
    },
];

const mapping2 = [
    {
        index: [1200, 0],
        sizes: [
            [728, 90],
            [650, 150],
            [890, 200],
            [2, 1]
        ],
    },
    {
        index: [768, 0],
        sizes: [
            [728, 90],
            [650, 150],
            [2, 1]
        ],
    },
    {
        index: [480, 0],
        sizes: [[300, 250], [300, 600], [300, 300], [2, 1], 'fluid'],
    },
    {
        index: [320, 0],
        sizes: [[300, 250], [300, 600], [300, 300], [2, 1], 'fluid'],
    },
];

const mapping3 = [
    {
        index: [1200, 0],
        sizes: [[300, 250]],
    },
    {
        index: [768, 0],
        sizes: [[300, 250]],
    },
    {
        index: [480, 0],
        sizes: [[300, 250], 'fluid'],
    },
    {
        index: [320, 0],
        sizes: [[300, 250], 'fluid'],
    },
];

const mapping4 = [
    {
        index: [1200, 0],
        sizes: [],
    },
    {
        index: [768, 0],
        sizes: [],
    },
    {
        index: [480, 0],
        sizes: ['fluid', [300, 250], [300, 600], [300, 300]],
    },
    {
        index: [320, 0],
        sizes: ['fluid', [300, 250], [300, 600], [300, 300]],
    },
];

const mapping5 = [
    {
        index: [1200, 0],
        sizes: [[300, 600]],
    },
    {
        index: [768, 0],
        sizes: [[300, 600]],
    },
    {
        index: [480, 0],
        sizes: [
            [320, 100],
            [320, 50],
            [360, 100],
            [360, 50],
            [1, 1],
        ],
    },
    {
        index: [320, 0],
        sizes: [
            [320, 100],
            [320, 50],
            [360, 100],
            [360, 50],
            [1, 1],
        ],
    },
];

const mapping6 = [
    {
        index: [1200, 0],
        sizes: [[1, 1]],
    },
    {
        index: [768, 0],
        sizes: [[1, 1]],
    },
    {
        index: [480, 0],
        sizes: [[1, 1]],
    },
    {
        index: [320, 0],
        sizes: [[1, 1]],
    },
];

const mapping7 = [
    {
        index: [1200, 0],
        sizes: [],
    },
    {
        index: [768, 0],
        sizes: [],
    },
    {
        index: [480, 0],
        sizes: [[320, 100], [320, 50], [360, 100], [360, 50], 'fluid'],
    },
    {
        index: [320, 0],
        sizes: [[320, 100], [320, 50], [360, 100], [360, 50], 'fluid'],
    },
];

export const adsLeadingNews: AdModel[] = [
    {
        id: 'div-gpt-ad-1686663374580-0',
        sizes: ['fluid', [300, 300], [300, 600], [300, 250]],
        mapping: mapping1,
        adUnitPath: '300x600_hp_1_mobile_hp_1',
        name: '',
    },
    {
        id: 'div-gpt-ad-1686663745617-0',
        sizes: ['fluid', [300, 250], [300, 300], [300, 600], [728, 90], [650, 150], [890, 200]],
        mapping: mapping2,
        adUnitPath: 'hor_hp_1_mobile_hp_2',
        name: '',
    },
];

export let adsSpecialProjectHeader = {
    id: 'div-gpt-ad-1688477842945-0',
    sizes: [
        [1800, 560],
        [360, 250],
        [320, 250],
    ],
    mapping: mappingSpecialProjectHeader,
    adUnitPath: 'special_project_header',
    name: '',
};

export let adsSpecialProject2 = {
    id: 'div-gpt-ad-1688481212166-0',
    sizes: [
        [300, 250],
        [300, 600],
    ],
    mapping: mappingSpecialProject1,
    adUnitPath: 'special_project_300x600_1',
    name: '',
};

export let adsSpecialProject3 = {
    id: 'div-gpt-ad-1688481361258-0',
    sizes: [
        [300, 600],
        [300, 250],
    ],
    mapping: mappingSpecialProject1,
    adUnitPath: 'special_project_300x600_2',
    name: '',
};

export let adsSpecialProject4 = {
    id: 'div-gpt-ad-1688481440680-0',
    sizes: [
        [300, 600],
        [300, 250],
        [728, 90],
        [650, 150],
    ],
    mapping: mappingSpecialProject2,
    adUnitPath: 'special_project_hor_1',
    name: '',
};

export let adsFourArticlesSingleHorizontalBanner = [
    {
        id: 'div-gpt-ad-1686668534230-0',
        sizes: [[890, 200], 'fluid', [650, 150], [300, 300], [300, 600], [300, 250], [728, 90]],
        mapping: mapping2,
        adUnitPath: 'hor_hp_2_mobile_hp_5',
        name: '',
    },
];

export let adsFourArticlesHorizontalAndRectBanner = [
    [
        {
            id: 'div-gpt-ad-1686668223162-0',
            sizes: ['fluid', [300, 250]],
            mapping: mapping3,
            adUnitPath: '300x250_hp_1_mobile_hp_4',
            name: '',
        },
        {
            id: 'div-gpt-ad-1686669075272-0',
            sizes: [[890, 200], [300, 250], [728, 90], 'fluid', [650, 150]],
            mapping: mapping2,
            adUnitPath: 'hor_hp_3_mobile_hp_7',
            name: '',
        },
    ],
    [
        {
            id: 'div-gpt-ad-1686669235848-0',
            sizes: [[300, 250], 'fluid'],
            mapping: mapping3,
            adUnitPath: '300x250_hp_2_mobile_hp_8',
            name: '',
        },
        {
            id: 'div-gpt-ad-1686669514284-0',
            sizes: ['fluid', [728, 90], [300, 300], [300, 600], [300, 250], [650, 150], [890, 200]],
            mapping: mapping2,
            adUnitPath: 'hor_hp_4_mobile_hp_9',
            name: '',
        },
    ],
];

export let adsFiveArticlesVerticalBanner = [
    {
        id: 'div-gpt-ad-1686664035731-0',
        sizes: [[300, 250], [300, 600], 'fluid', [300, 300]],
        mapping: mapping1,
        adUnitPath: '300x600_hp_2_mobile_hp_3',
        name: '',
    },
    {
        id: 'div-gpt-ad-1686668799663-0',
        sizes: [[300, 250], 'fluid', [300, 300], [300, 600]],
        mapping: mapping1,
        adUnitPath: '300x600_hp_3_mobile_hp_6',
        name: '',
    },
    {
        id: 'div-gpt-ad-1686669704138-0',
        sizes: [[300, 250], [300, 300], [300, 600], 'fluid'],
        mapping: mapping1,
        adUnitPath: '300x600_hp_4_mobile_hp_10',
        name: 'check',
    },
];

//TODO Break down to category and open article
export const ads: AdModel[] = [
    {
        id: 'div-gpt-ad-1686663374580-0',
        sizes: ['fluid', [300, 300], [300, 600], [300, 250]],
        mapping: mapping1,
        adUnitPath: '300x600_hp_1_mobile_hp_1',
        name: 'ad1',
    },
    {
        id: 'div-gpt-ad-1686663745617-0',
        sizes: ['fluid', [300, 250], [300, 300], [300, 600], [728, 90], [650, 150], [890, 200]],
        mapping: mapping2,
        adUnitPath: 'hor_hp_1_mobile_hp_2',
        name: 'ad2',
    },
    {
        id: 'div-gpt-ad-1686664035731-0',
        sizes: [[300, 250], [300, 600], 'fluid', [300, 300]],
        mapping: mapping1,
        adUnitPath: '300x600_hp_2_mobile_hp_3',
        name: 'ad3',
    },
    {
        id: 'div-gpt-ad-1686668223162-0',
        sizes: ['fluid', [300, 250]],
        mapping: mapping3,
        adUnitPath: '300x250_hp_1_mobile_hp_4',
        name: 'ad4',
    },
    {
        id: 'div-gpt-ad-1686668534230-0',
        sizes: [[890, 200], 'fluid', [650, 150], [300, 300], [300, 600], [300, 250], [728, 90]],
        mapping: mapping2,
        adUnitPath: 'hor_hp_2_mobile_hp_5',
        name: 'ad5',
    },
    {
        id: 'div-gpt-ad-1686668799663-0',
        sizes: [[300, 250], 'fluid', [300, 300], [300, 600]],
        mapping: mapping1,
        adUnitPath: '300x600_hp_3_mobile_hp_6',
        name: 'ad6',
    },
    {
        id: 'div-gpt-ad-1686669075272-0',
        sizes: [[890, 200], [300, 250], [728, 90], 'fluid', [650, 150]],
        mapping: mapping2,
        adUnitPath: 'hor_hp_3_mobile_hp_7',
        name: 'ad7',
    },
    {
        id: 'div-gpt-ad-1686669235848-0',
        sizes: [[300, 250], 'fluid'],
        mapping: mapping3,
        adUnitPath: '300x250_hp_2_mobile_hp_8',
        name: 'ad8',
    },
    {
        id: 'div-gpt-ad-1686669514284-0',
        sizes: ['fluid', [728, 90], [300, 300], [300, 600], [300, 250], [650, 150], [890, 200]],
        mapping: mapping2,
        adUnitPath: 'hor_hp_4_mobile_hp_9',
        name: 'ad9',
    },
    {
        id: 'div-gpt-ad-1686669704138-0',
        sizes: [[300, 250], [300, 300], [300, 600], 'fluid'],
        mapping: mapping1,
        adUnitPath: '300x600_hp_4_mobile_hp_10',
        name: 'ad10',
    },
    {
        id: 'div-gpt-ad-1686729948746-0',
        sizes: [[300, 250], 'fluid', [300, 600], [300, 300]],
        mapping: mapping1,
        adUnitPath: '300x600_cat_1_mobile_cat_1',
        name: 'ad11',
    },
    {
        id: 'div-gpt-ad-1686730111240-0',
        sizes: ['fluid', [300, 300], [300, 600], [300, 250]],
        mapping: mapping4,
        adUnitPath: 'mobile_cat_2',
        name: 'ad12',
    },
    {
        id: 'div-gpt-ad-1686730516120-0',
        sizes: [[300, 250], 'fluid'],
        mapping: mapping4,
        adUnitPath: 'mobile_cat_3',
        name: 'ad13',
    },
    {
        id: 'div-gpt-ad-1686730624217-0',
        sizes: [[300, 250], 'fluid'],
        mapping: mapping4,
        adUnitPath: 'mobile_cat_4',
        name: 'ad14',
    },
    {
        id: 'div-gpt-ad-1686734934138-0',
        sizes: [
            [320, 50],
            [360, 100],
            [300, 600],
            [1, 1],
            [360, 50],
            [320, 100],
        ],
        mapping: mapping5,
        adUnitPath: '300x600_article_1_mobile_header',
        name: 'ad15',
    },
    {
        id: 'div-gpt-ad-1686735342680-0',
        sizes: [[728, 90], [300, 250], [2, 1], 'fluid'],
        mapping: mapping2,
        adUnitPath: 'hor_article_1_mobile_1',
        name: 'ad16',
    },
    {
        id: 'div-gpt-ad-1686735601516-0',
        sizes: [[300, 300], [300, 600], 'fluid', [300, 250], [728, 90], [2, 1]],
        mapping: mapping2,
        adUnitPath: 'hor_article_2_mobile_2',
        name: 'ad17',
    },
    {
        id: 'div-gpt-ad-1686735785296-0',
        sizes: [[300, 250], [728, 90], [300, 600], [300, 300], [2, 1], 'fluid'],
        mapping: mapping2,
        adUnitPath: 'hor_article_3_mobile_3',
        name: 'ad18',
    },
    {
        id: 'div-gpt-ad-1686735949156-0',
        sizes: ['fluid', [650, 150], [300, 300], [300, 600], [728, 90], [300, 250]],
        mapping: mapping2,
        adUnitPath: 'hor_article_4_mobile_4',
        name: 'ad19',
    },
    {
        id: 'div-gpt-ad-1686736152338-0',
        sizes: [[300, 250], [300, 300], 'fluid'],
        mapping: mapping3,
        adUnitPath: '300x250_article_1_mobile_5',
        name: 'ad20',
    },
    {
        id: 'div-gpt-ad-1686736380759-0',
        sizes: ['fluid', [300, 250], [300, 300], [728, 90]],
        mapping: mapping2,
        adUnitPath: 'hor_article_5_mobile_6',
        name: 'ad21',
    },
    {
        id: 'div-gpt-ad-1686736631908-0',
        sizes: [1, 1],
        mapping: mapping6,
        adUnitPath: 'non_standard_',
        name: 'ad22',
    },
    {
        id: 'div-gpt-ad-1686737083995-0',
        sizes: ['fluid', [360, 50], [320, 50], [320, 100], [360, 100]],
        mapping: mapping7,
        adUnitPath: 'mobile_sticky',
        name: 'ad23',
    },
    {
        id: 'div-gpt-ad-1681819753853-0',
        sizes: [[320, 100], [360, 100], [320, 50], 'fluid'],
        mapping: mapping5,
        adUnitPath: 'mobile_header',
        name: 'ad24',
    },
];
