import React, { FunctionComponent } from 'react';
import styles from '../../styles/Footer.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { Routes } from '../../constants/Routes';
import { GoogleAdDesktop } from '../Ads/GoogleAdDesktop';
import { ads } from '../../services/ads/constants';
import { GoogleAdMobile } from '../Ads/GoogleAdMobile';

const Footer: FunctionComponent = () => {
    return (
        <footer className={`${styles.footerContainer} container mt-3`}>
            <div
                className={`${styles.footerContent} h-100 pt-4 d-flex flex-column-reverse flex-md-row justify-content-between justify-content-md-between align-items-center`}>
                <div className={`${styles.informationLinks}`}>
                    <div className='text-start d-flex flex-column flex-md-row mb-0 mb-md-2'>
                        <Link target='_blank' href='https://corp.sportal.bg/' className='me-3 mb-2 mb-md-auto'>
                            Sportal Media Group
                        </Link>
                        <Link href='/privacy-policy#gdpr' className='me-3 mb-2 mb-md-auto'>
                            Лични данни
                        </Link>
                        <a
                            id='ot-sdk-btn'
                            href='javascript: void(0);'
                            className={`me-0 mb-0 mb-md-0 me-md-3 me-lg-3 pb-1 ot-sdk-show-settings ${styles.privacyPolicy}`}>
                            Настройки за Поверителност
                        </a>
                        <div className='d-none d-md-flex d-lg-flex flex-row justify-content-start ms-2'>
                            <Link
                                href='https://www.facebook.com/woman.bg'
                                target='_blank'
                                className='social-media-logos d-flex flex-row justify-content-center me-2'>
                                <Image
                                    alt='facebook woman social'
                                    width={20}
                                    height={20}
                                    src={'/facebook.svg'}
                                    className='normalize-nextjs-image'
                                />
                            </Link>
                            <Link
                                href='https://www.instagram.com/woman.bg/'
                                target='_blank'
                                className='social-media-logos d-flex flex-row justify-content-center me-2'>
                                <Image
                                    alt='instagram woman social'
                                    width={20}
                                    height={20}
                                    src={'/instagram.svg'}
                                    className='normalize-nextjs-image'
                                />
                            </Link>
                            <Link
                                href='https://www.tiktok.com/@woman.bg'
                                target='_blank'
                                className='social-media-logos d-flex flex-row justify-content-center me-2'>
                                <Image
                                    alt='tiktok woman social'
                                    width={20}
                                    height={20}
                                    src={'/tiktok.svg'}
                                    className='normalize-nextjs-image'
                                />
                            </Link>
                            <Link
                                href='https://www.youtube.com/@Womanbg-hi1lg/featured'
                                target='_blank'
                                className='social-media-logos d-flex flex-row justify-content-center'>
                                <Image
                                    alt='youtube woman social'
                                    width={20}
                                    height={20}
                                    src={'/youtube.svg'}
                                    className='normalize-nextjs-image'
                                />
                            </Link>
                        </div>
                    </div>
                    <div className='mb-2 mb-md-0 mt-2 mt-md-0 mt-lg-0'>All rights reserved</div>
                </div>
                <div className='d-flex flex-row justify-content-between justify-content-md-end mb-3 mb-md-0'>
                    <Link className='w-100 text-start text-md-end' href={Routes.openHomepage()}>
                        <Image
                            src={'/woman_logo.svg'}
                            alt={'woman.bg logo'}
                            fill
                            className={`${styles.womanBgLogo} normalize-nextjs-image`}
                        />
                    </Link>
                    <div className='w-100 d-flex d-md-none flex-row justify-content-end align-items-center'>
                        <Link
                            href='https://www.facebook.com/woman.bg'
                            target='_blank'
                            className='social-media-logos d-flex flex-row justify-content-center me-2'>
                            <Image
                                alt='facebook woman social'
                                width={20}
                                height={20}
                                src={'/facebook.svg'}
                                className='normalize-nextjs-image'
                            />
                        </Link>
                        <Link
                            href='https://www.instagram.com/woman.bg/'
                            target='_blank'
                            className='social-media-logos d-flex flex-row justify-content-center me-2'>
                            <Image
                                alt='instagram woman social'
                                width={20}
                                height={20}
                                src={'/instagram.svg'}
                                className='normalize-nextjs-image'
                            />
                        </Link>
                        <Link
                            href='https://www.tiktok.com/@woman.bg'
                            target='_blank'
                            className='social-media-logos d-flex flex-row justify-content-center me-2'>
                            <Image
                                alt='tiktok woman social'
                                width={20}
                                height={20}
                                src={'/tiktok.svg'}
                                className='normalize-nextjs-image'
                            />
                        </Link>
                        <Link
                            href='https://www.youtube.com/@Womanbg-hi1lg/featured'
                            target='_blank'
                            className='social-media-logos d-flex flex-row justify-content-center'>
                            <Image
                                alt='youtube woman social'
                                width={20}
                                height={20}
                                src={'/youtube.svg'}
                                className='normalize-nextjs-image'
                            />
                        </Link>
                    </div>
                </div>
            </div>
            <GoogleAdDesktop ad={ads[21]} />
            <GoogleAdMobile ad={ads[21]} />
        </footer>
    );
};

export default Footer;
