import React, { FunctionComponent } from 'react';

type Props = {
    collapseContainerId: string;
    imageSrc: string;
    className?: string;
    onClick?: () => void;
};

export const NavBarControls: FunctionComponent<Props> = ({ collapseContainerId, imageSrc, className, onClick }) => {
    return (
        <div
            className={`navbar-toggler p-0 me-1 ${className}`}
            role='button'
            data-bs-toggle='collapse'
            data-bs-target={`#${collapseContainerId}`}
            aria-controls={collapseContainerId}
            aria-expanded='false'
            aria-label='Toggle navigation controls'
            onClick={() => (onClick ? onClick() : null)}>
            <img src={imageSrc} alt={'menu icon'} />
        </div>
    );
};

export default NavBarControls;
