import React, { FunctionComponent, PropsWithChildren, useState, useEffect } from 'react';
import Footer from '../Footer/Footer';
import { NavBar } from '../Navbar/NavBar';

const Layout: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [isNavFixed, setIsNavFixed] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Determine whether the navigation bar should be fixed or relative
            // 1. Detect the first child of the body if it contains the google ad
            // 2. Apply the setIsNavFixed using the height of the google ad if the first child is a google ad
            // If the first child is not a google and and the isNavFixed is not set, set it to true
            if (isBodyFirstChildGoogleAd()) {
                const childHeight = getBodyFirstChildHeight();
                setIsNavFixed(window.scrollY >= childHeight);
            } else if (!isNavFixed) {
                setIsNavFixed(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <NavBar className={isNavFixed ? 'fixed-top' : 'position-relative'} />
            <div className={`website-container ${isNavFixed ? 'fixed-nav' : ''}`}>{children}</div>
            <Footer />
        </>
    );
};

export default Layout;

//Detect if the first child is a google ad by using the id of the nextjs container id
const isBodyFirstChildGoogleAd = () => {
    const firstChild = document.body.firstChild;
    const NEXT_JS_ID = '__next';

    if (firstChild instanceof HTMLElement) {
        const childId = firstChild.getAttribute('id');
        return childId !== NEXT_JS_ID;
    }

    return false;
};

//Extracts the height of the first child of the body element
const getBodyFirstChildHeight = () => {
    const firstChild = document.body.firstChild;

    if (firstChild instanceof HTMLElement) {
        return firstChild.clientHeight;
    }

    return 0;
};
