import React, { FunctionComponent, useEffect, useState } from 'react';
import { CategoryModel } from '../../services/content/category/models/CategoryModel';
import Link from 'next/link';
import { Routes } from '../../constants/Routes';
import { useRouter } from 'next/router';
import { WIDTH_FOR_MENU_ICON_TO_APPEAR } from './NavBar';

type Props = {
    id: string;
    className: string;
    display: boolean;
    categories: CategoryModel[];
    onClick: () => void;
};

export const NavBarLinks: FunctionComponent<Props> = ({ id, className, categories, display, onClick }) => {
    const router = useRouter();
    const [canControlDisplayState, setCanControlDisplayState] = useState(false);

    useEffect(() => {
        setCanControlDisplayState(window.innerWidth <= WIDTH_FOR_MENU_ICON_TO_APPEAR);
    }, []);

    // WARNING: Adding the setTimeout because of this issue: https://github.com/vercel/next.js/issues/43972
    // Cannot add dynamic parent folder because this issue: https://github.com/vercel/next.js/issues/45106
    // Adding router.push() because of the above issues
    if (categories && display) {
        return (
            <div className={`${className} w-100`} id={id}>
                <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
                    {categories
                        .filter((category) => category.active)
                        .map((category) => {
                            return (
                                <li className='nav-item' key={`nav-menu-item-${category.id}`}>
                                    <a
                                        className='nav-link'
                                        href={Routes.openCategory(category?.seo?.slug)}
                                        onClick={() => {
                                            canControlDisplayState ? onClick() : null;
                                            // router.push(Routes.openCategory(category?.seo?.slug));
                                        }}>
                                        {category.title}
                                    </a>
                                </li>
                            );
                        })}
                </ul>
            </div>
        );
    }

    return null;
};

export default NavBarLinks;
