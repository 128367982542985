import { AdModel } from './models/AdModel';
import { AD_MANAGER_ID } from './constants';
import { TemplateTypes } from '../../constants/Enums';

export const getAdsForSection = (ads: any[], template: TemplateTypes, sectionAdsCounter: any) => {
    return ads[sectionAdsCounter[template]];
};

export const checkIfAdIdExists = (id: string) => {
    return document.getElementById(id);
};

export const safeRenderAd = (ad: AdModel | null) => {
    try {
        if (ad && window.googletag && googletag.apiReady) {
            renderAd(ad);
        } else {
            setTimeout(() => renderAd(ad), 800);
        }
    } catch (e) {
        setTimeout(() => renderAd(ad), 800);
    }
};

export const safeSetAdsTargeting = (key: string, value: string | string[]) => {
    try {
        if (window.googletag && googletag.apiReady) {
            window.googletag.pubads().setTargeting(key, value);
        } else {
            setTimeout(() => window.googletag.pubads().setTargeting(key, value), 800);
        }
    } catch (e) {
        setTimeout(() => window.googletag.pubads().setTargeting(key, value), 800);
    }
};

const renderAd = (ad: AdModel | null): void => {
    if (ad && window.googletag && googletag.apiReady) {
        const adMapping = ad.mapping;
        try {
            let bannerMapping = googletag.sizeMapping();

            adMapping.forEach((mapping) => {
                bannerMapping = bannerMapping.addSize(mapping.index, mapping.sizes);
            });

            const adSlot = googletag.defineSlot(`${AD_MANAGER_ID}${ad.adUnitPath}`, ad.sizes, ad.id);
            adSlot?.defineSizeMapping(bannerMapping.build());
            adSlot?.addService(googletag.pubads());
            googletag.pubads().enableSingleRequest();
            // googletag.pubads().collapseEmptyDivs();
            googletag.enableServices();
            googletag.display(ad.id);
        } catch (e: any) {
            console.error('Google ad render error: ', e);
        }
    }
};

export const safeDestroyAd = () => {
    try {
        if (googletag && googletag.pubadsReady) {
            destroyAd();
        } else {
            setTimeout(() => destroyAd(), 500);
        }
    } catch (e: any) {
        setTimeout(() => destroyAd(), 500);
    }
};

export const destroyAd = () => {
    try {
        if (window.googletag && googletag.apiReady) {
            googletag.destroySlots();
        }
    } catch (e) {
        console.error('Google ad destroy error: ', e);
    }
};
