import { FunctionComponent, useEffect, useState } from 'react';
import { AdModel } from '../../services/ads/models/AdModel';
import { safeDestroyAd, safeRenderAd } from '../../services/ads/AdService';

type Props = {
    className?: string;
    ad: AdModel | null;
};

export const GoogleAdMobile: FunctionComponent<Props> = ({ ad, className }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 720);

        if (window.innerWidth <= 720) {
            safeRenderAd(ad);
            return () => {
                safeDestroyAd();
            };
        }
    }, []);

    if (isMobile) {
        return <div data-ad-unit-path={ad?.adUnitPath} id={ad?.id} className={className ? className : ''}></div>;
    }

    return null;
};
